<template>
  <!-- <div>
    <a href="javascript:;" class="login_type_2" @click="getGoogle">
      <img class="facebook" src="../assets/img/google.png" />
      <div>google</div>
    </a>
  </div> -->
  <div>
    <div class="g_id_signin2" id="g_id_signin2"></div>
  </div>
</template>
<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {
    client_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      clientId: "",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // this.init(this.handleCredentialResponse);
  },
  methods: {
    initGoogle() {
      this.$nextTick(() => {
      setTimeout(() => {
        this.init(this.handleCredentialResponse);
      }, 100);
    });
    },
    init(fn) {
      // window.addEventListener("load", this.gload(fn));
      this.gload(fn);
    },
    gload(fn) {
      if (window.google && window.google.accounts) {
        window.google.accounts.id.initialize({
          // 主要就是填写client_id
          client_id: this.clientId,
          callback: fn,
          locale: "en-US",
        });
        window.google.accounts.id.renderButton(
          document.getElementById("g_id_signin2"),
          {
            theme: "filled_blue",
            size: "large",
            shape: "circle",
            // width: '336',
            type: "icon",
            text: "signin_with",
          }
        );
      }
    },
    decodeJwtResponse(token) {
      const strings = token.split(".");
      return JSON.parse(
        decodeURIComponent(
          escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")))
        )
      );
    },
    async handleCredentialResponse(response) {
      if (response) {
        // const response1 = this.decodeJwtResponse(response)
        // const responsePayload = this.decodeJwtResponse(response.credential);
        // console.log(response);
        // console.log('responsePayload', responsePayload);
        // console.log("ID: " + responsePayload.sub);
        // console.log('Full Name: ' + responsePayload.name);
        // console.log('Given Name: ' + responsePayload.given_name);
        // console.log('Family Name: ' + responsePayload.family_name);
        // console.log("Image URL: " + responsePayload.picture);
        // console.log("Email: " + responsePayload.email);
        this.Login(response.credential);
      }
    },
    Login(access_token) {
      let game_id = this.$route.query.g;
      let params = {
        game_id,
        access_token: access_token,
      };
      this.$http.post("/login/googleLogin", params).then((res) => {
        if (res.data.code === 200) {
          let token = res.data.data.token;
          localStorage.setItem("token", JSON.stringify(token));
          this.selectServe = true;
          this.$router.push("/consume");
          this.$message.success(res.data.msg);
          setTimeout(() => {
            this.showLoading = false;
          }, 2000);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleClick() {
      console.log(this.clientId);
    },
  },
  beforeDestroy() {
    window.removeEventListener("load", this.gload());
  },
};
</script>

<style scoped lang="scss"></style>
